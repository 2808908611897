import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class AdsService {
  static async update(tenant, id, data, location?) {
    const body = {
      tenantId: tenant._id,
      tenantName: tenant.name,
      id,
      data,
      location,
    };

    authAxios.put(
      `/tenant/${tenant._id}/ads/${id}`,
      body,
    );
  }

  static async updatePrograms(data, location) {
    const body = {
      data,
      location,
    };

    authAxios.put(
      `/ads/updates`,
      body,
    );

    return true;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(
      `/tenant/${tenantId}/ads`,
      {
        params,
      },
    );

    return response.data;
  }

  static async create(tenantId, tenantName, data, type='') {
    const body = {
      tenantId,
      tenantName,
      data,
      type,
    };

    const response = await authAxios.post(
      `/tenant/${tenantId}/ads/create-program`,
      body,
    );

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/ads/import`,
      body,
    );

    return response.data;
  }

  static async getProgramInfo(programId) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/ads/${programId}`,
    );

    return response.data;
  }

  static async pauseProgram(tenantId, tenantName, programId) {
    const body = {
      tenantId,
      tenantName,
    };

    authAxios.put(
      `/tenant/${tenantId}/ads/pause/${programId}`,
      body,
    );
  }

  static async resumeProgram(tenantId, tenantName, programId) {
    const body = {
      tenantId,
      tenantName,
    };

    authAxios.put(
      `/tenant/${tenantId}/ads/resume/${programId}`,
      body,
    );
  }

  static async stopProgram(tenantId, tenantName, programId) {
    const body = {
      tenantId,
      tenantName,
    };

    authAxios.put(
      `/tenant/${tenantId}/ads/stop/${programId}`,
      body,
    );
  }

  static async resumeStoppedProgram(tenantId, tenantName, programId) {
    const body = {
      tenantId,
      tenantName,
    };

    authAxios.put(
      `/tenant/${tenantId}/ads/resume-stopped/${programId}`,
      body,
    );
  }

  static async terminateProgram(tenantId, tenantName, programId) {
    const body = {
      tenantId,
      tenantName,
    };

    authAxios.delete(
      `/tenant/${tenantId}/ads/terminate/${programId}`,
      body,
    );
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/ads`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/ads/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }

  static async fetchMaxBidStatuses() {
    const response = await authAxios.get('/ads/max-bid-status/fetch-all');
    return response.data;
  }

  static async fetchMaxBidStatus(programId) {
    const body = {
      programId,
    };

    const response = await authAxios.post('/ads/max-bid-status/fetch', body);
    return response.data;
  }

  static async updateMaxBidTablePreferences(userId, data) {
    const body = {
      userId,
      data,
    };

    await authAxios.post('/ads/mbt-update-preferences', body);
    return;
  }

  static async getMaxBidTablePreferences(userId) {
    const body = {
      userId,
    };

    const response = await authAxios.post('/ads/mbt-get-preferences', body);
    return response ? response.data : null;
  }
}
