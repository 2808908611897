import Permissions from 'src/security/permissions';
import config from 'src/config';

const permissions = Permissions.values;

const privateRoutes = [
  {
    path: '/',
    loader: () =>
      import('src/view/dashboard/DashboardPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/profile',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/password-change',
    loader: () =>
      import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/tenant',
    loader: () =>
      import('src/view/tenant/list/TenantListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/new',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/:id/edit',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/plan',
    loader: () => import('src/view/plan/PlanPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/user',
    loader: () => import('src/view/user/list/UserPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/user/new',
    loader: () => import('src/view/user/new/UserNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/user/importer',
    loader: () =>
      import('src/view/user/importer/UserImporterPage'),
    permissionRequired: permissions.userImport,
    exact: true,
  },
  {
    path: '/user/:id/edit',
    loader: () => import('src/view/user/edit/UserEditPage'),
    permissionRequired: permissions.userEdit,
    exact: true,
  },
  {
    path: '/user/:id',
    loader: () => import('src/view/user/view/UserViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/audit-logs',
    loader: () => import('src/view/auditLog/AuditLogPage'),
    permissionRequired: permissions.auditLogRead,
  },

  {
    path: '/settings',
    loader: () =>
      import('src/view/settings/SettingsFormPage'),
    permissionRequired: permissions.settingsEdit,
  },

  {
    path: '/user-data',
    loader: () =>
      import('src/view/userData/list/UserDataListPage'),
    permissionRequired: permissions.userDataRead,
    exact: true,
  },
  {
    path: '/user-data/new',
    loader: () =>
      import('src/view/userData/form/UserDataFormPage'),
    permissionRequired: permissions.userDataCreate,
    exact: true,
  },
  {
    path: '/user-data/importer',
    loader: () =>
      import(
        'src/view/userData/importer/UserDataImporterPage'
      ),
    permissionRequired: permissions.userDataImport,
    exact: true,
  },
  {
    path: '/user-data/:id/edit',
    loader: () =>
      import('src/view/userData/form/UserDataFormPage'),
    permissionRequired: permissions.userDataEdit,
    exact: true,
  },
  {
    path: '/user-data/:id',
    loader: () =>
      import('src/view/userData/view/UserDataViewPage'),
    permissionRequired: permissions.userDataRead,
    exact: true,
  },

  {
    path: '/daily-report',
    loader: () =>
      import('src/view/dailyReport/list/DailyReportListPage'),
    permissionRequired: permissions.dailyReportRead,
    exact: true,
  },
  {
    path: '/daily-report/new',
    loader: () =>
      import('src/view/dailyReport/form/DailyReportFormPage'),
    permissionRequired: permissions.dailyReportCreate,
    exact: true,
  },
  {
    path: '/daily-report/importer',
    loader: () =>
      import(
        'src/view/dailyReport/importer/DailyReportImporterPage'
      ),
    permissionRequired: permissions.dailyReportImport,
    exact: true,
  },
  {
    path: '/daily-report/:id/edit',
    loader: () =>
      import('src/view/dailyReport/form/DailyReportFormPage'),
    permissionRequired: permissions.dailyReportEdit,
    exact: true,
  },
  {
    path: '/daily-report/:id',
    loader: () =>
      import('src/view/dailyReport/view/DailyReportViewPage'),
    permissionRequired: permissions.dailyReportRead,
    exact: true,
  },

  {
    path: '/monthly-reports',
    loader: () =>
      import('src/view/monthlyReports/list/MonthlyReportsListPage'),
    permissionRequired: permissions.monthlyReportsRead,
    exact: true,
  },
  {
    path: '/monthly-reports/new',
    loader: () =>
      import('src/view/monthlyReports/form/MonthlyReportsFormPage'),
    permissionRequired: permissions.monthlyReportsCreate,
    exact: true,
  },
  {
    path: '/monthly-reports/importer',
    loader: () =>
      import(
        'src/view/monthlyReports/importer/MonthlyReportsImporterPage'
      ),
    permissionRequired: permissions.monthlyReportsImport,
    exact: true,
  },
  {
    path: '/monthly-reports/:id/edit',
    loader: () =>
      import('src/view/monthlyReports/form/MonthlyReportsFormPage'),
    permissionRequired: permissions.monthlyReportsEdit,
    exact: true,
  },
  {
    path: '/monthly-reports/:id',
    loader: () =>
      import('src/view/monthlyReports/view/MonthlyReportsViewPage'),
    permissionRequired: permissions.monthlyReportsRead,
    exact: true,
  },

  {
    path: '/ads',
    loader: () =>
      import('src/view/ads/list/AdsListPage'),
    permissionRequired: permissions.adsRead,
    exact: true,
  },
  {
    path: '/ads/new',
    loader: () =>
      import('src/view/ads/form/AdsFormPage'),
    permissionRequired: permissions.adsCreate,
    exact: true,
  },
  {
    path: '/ads/importer',
    loader: () =>
      import(
        'src/view/ads/importer/AdsImporterPage'
      ),
    permissionRequired: permissions.adsImport,
    exact: true,
  },
  {
    path: '/ads/:id/edit',
    loader: () =>
      import('src/view/ads/form/AdsFormPage'),
    permissionRequired: permissions.adsEdit,
    exact: true,
  },
  {
    path: '/ads/:id',
    loader: () =>
      import('src/view/ads/view/AdsViewPage'),
    permissionRequired: permissions.adsRead,
    exact: true,
  },
  {
    path: '/max-bid-table',
    loader: () =>
      import('src/view/maxBidTable/MaxBidTable'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/program-logs',
    loader: () =>
      import('src/view/programLog/ProgramLogPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/leads',
    loader: () =>
      import('src/view/leads/LeadsPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/time-settings',
    loader: () => 
      import('src/view/timeSettings/TimeSettingsPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/partnerConfig',
    loader: () =>
      import('src/view/partnerConfig/PartnerConfigPage'),
    permissionRequired: null,
    exact: true,
  },
].filter(Boolean);

const publicRoutes = [
  {
    path: '/auth/signin',
    loader: () => import('src/view/auth/SigninPage'),
  },
  {
    path: '/auth/signup',
    loader: () => import('src/view/auth/SignupPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () =>
      import('src/view/auth/ForgotPasswordPage'),
  },
].filter(Boolean);

const emptyTenantRoutes = [
  {
    path: '/auth/tenant',
    loader: () => import('src/view/auth/TenantPage'),
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () =>
      import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () =>
      import('src/view/auth/EmailUnverifiedPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/auth/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/invitation',
    loader: () => import('src/view/auth/InvitationPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('src/view/auth/VerifyEmailPage'),
  },
  {
    path: '/403',
    loader: () =>
      import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () =>
      import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () =>
      import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  emptyTenantRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  simpleRoutes,
};
