import React, { useState, useEffect } from 'react';
import Header from 'src/view/layout/Header';
import Menu from 'src/view/layout/Menu';
import { useRouteMatch } from 'react-router-dom';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import authSelectors from 'src/modules/auth/authSelectors';
import { useSelector, useDispatch } from 'react-redux';
import selectors from 'src/modules/tenant/list/tenantListSelectors';
import authActions from 'src/modules/auth/authActions';
import tenantActions from 'src/modules/tenant/list/tenantListActions';

function Layout(props) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const match = useRouteMatch();

  const menuVisible = useSelector(
    layoutSelectors.selectMenuVisible,
  );

  const currentTenant : any = useSelector(
    authSelectors.selectCurrentTenant,
  );

  let allRows = useSelector(selectors.selectRows);
  const hasRows = useSelector(selectors.selectHasRows);
  const dispatch = useDispatch();
  const doSelectTenant = (tenant) => {
    dispatch(authActions.doSelectTenant(tenant));
  };

  useEffect(() => {
    dispatch(tenantActions.doFetch(undefined, undefined, true));
  }, [dispatch]);

  const listLoading = useSelector(selectors.selectLoading);

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    const filteredData = value
      ? allRows.filter((item) =>
          item.name.toLowerCase().includes(value.toLowerCase())
        )
      : []; // No rows if search term is blank

    setFilteredResults(filteredData);
  };

  return (
    <div className="flex h-full dark:bg-gray-600">
      <Menu url={match.url} />
      <div
        className={`${
          menuVisible ? 'hidden' : 'flex'
        } sm:flex md:flex lg:flex bg-gray-100 dark:bg-gray-600 flex-col flex-auto min-h-screen overflow-x-hidden`}
      >
        <Header />
        <div className="p-6">
          {listLoading && ''}
          {!listLoading && hasRows && allRows.length > 1 && (
            <div>
              <input
                type="text"
                placeholder="Search Tenants..."
                value={searchTerm}
                onChange={handleSearch}
                className="border border-gray-300 p-2 rounded-lg"
              />
              <div className="flex flex-wrap gap-4 p-3">
                {filteredResults.length ? filteredResults.map((row : any) => (
                  <button
                    className={`mr-2 text-sm disabled:opacity-50 disabled:cursor-default px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600 dark:border dark:border-white ${row.name === currentTenant.name && 'bg-blue-400 test-white'}`}
                    key={row._id}
                    onClick={() =>
                      doSelectTenant(row)
                    }
                  >
                    {row.name}
                  </button>
                )) : ''}
              </div>
            </div>
          )}
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default Layout;
